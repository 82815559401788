const pastas = [
{pasta : "Instituicao",
start : "Documentos",
middle : " da ",
end : "Instituição",
subPastas : ["Ata atual diretoria", "Estatuto Social", "Relatório anual"]},

{pasta : "Contabeis",
start : "Documentos",
middle : " ",
end : "Contábeis",
subPastas : ["Ano 2022"]},
// subPastas : ["Ano 2023", "Ano 2022"]},

{pasta : "Parcerias",
start : "Parcerias",
middle : " ",
end : "Públicas",
subPastas : ["Prefeitura de Vinhedo - 2024","Prefeitura de Vinhedo - 2023","Prefeitura de Vinhedo - 2022"]}

,{pasta : "Relatorios",
start : "Relatórios ",
middle : "mensais e ",
end : "planos de ação",
subPastas : ["Ano 2023"]}
]

export default pastas
