import { BannerHome } from "./BannerHome";
import Instituto from "./Instituto";
import Atendimentos from "./Atendimentos";
import Galeria from "./Galeria";


export default function Home ()
{
  return (
    <>
      <BannerHome />
      <Instituto />
      <Atendimentos />
      <Galeria />
    </>


  )
}